import React from "react";
import ReactDOM from "react-dom";
import { Pager } from "@progress/kendo-react-data-tools";

const TopPager = (props) => {
  const topPager = document && document.getElementById("top_pager");
  return topPager ? ReactDOM.createPortal(<Pager {...props} />, topPager) : null;
};

export const CustomPager = (props) => {
  return (
    <>
      <TopPager {...props} />
      <Pager {...props} />
    </>
  );
};
