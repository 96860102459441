import React from "react";

import { BaseMultiSelect, BaseCheckbox, DatePickerDropdownOptions, NumericDropdownOptions } from "./";
import { BaseInput } from "./BaseInput/BaseInput";

export const CustomInput = ({ inputType, ...props }) => {
  switch (inputType) {
    case "multiSelect":
      return <BaseMultiSelect {...props} />;
    case "checkbox":
      return <BaseCheckbox {...props} />;
    case "input":
      return <BaseInput {...props} />;
    case "customPeriodDropdown":
      return <DatePickerDropdownOptions {...props} />;
    case "customNumericDropdown":
      return <NumericDropdownOptions {...props} />;
    default:
      return null;
  }
};
