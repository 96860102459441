import React from "react";
import { Button } from "@progress/kendo-react-buttons";

import { useGridData } from "../../../../../context";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const DeletePresetConfirmation = ({ show, setShow, selectedPreset }) => {
  const { onPresetDelete } = useGridData();

  const onContinueHandler = async () => {
    await onPresetDelete(selectedPreset._id);
    setShow(false);
  };

  return (
    <>
      {show && (
        <Dialog title={"Delete a preset?"} onClose={setShow}>
          <p className="text-center m-2">Are you sure you want to delete preset "{selectedPreset._id}"?</p>
          <DialogActionsBar>
            <Button onClick={setShow}>Cancel</Button>
            <Button type="button" themeColor={"error"} onClick={onContinueHandler}>
              Delete
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
