import React from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Tooltip } from "@progress/kendo-react-tooltip";

export const TruncateCell = ({ dataItem, field, ...props }) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);

  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      <Tooltip content={(props) => props.title} anchorElement="target" position="top">
        <p className="mb-0 text-truncate" title={dataItem[field]}>
          {dataItem[field]}
        </p>
      </Tooltip>
    </td>
  );
};
