import React, { useState, useEffect } from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { BaseDropDownList } from "../../../../Inputs";

export const DropdownValueCell = ({ data, ...props }) => {
  const itemValue = props.dataItem[props.field];
  const { dataItemKey = "_id", textField = "Description" } = props.options;
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (data) {
      setValue(data.find((val) => val[dataItemKey] === itemValue));
    }
  }, [itemValue, dataItemKey, data]);

  const onBlurHandler = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: { [props.field]: value[dataItemKey], [`_webhook${props.field}`]: value[dataItemKey] },
    });
  };

  const onChangeHandler = (e) => setValue(e.value);

  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      <BaseDropDownList
        value={value}
        onBlur={onBlurHandler}
        onChange={onChangeHandler}
        data={data}
        dataItemKey={dataItemKey}
        textField={textField}
      />
    </td>
  );
};
