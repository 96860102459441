import React from "react";
import ReactDOM from "react-dom";
import { Spinner, SpinnerTable } from "../Spinner/Spinner";

export const TableSpinner = ({ gridRef }) => {
  const gridWrapper = document && document.getElementById("grid_wrapper");
  const gridContent = ((gridRef && gridRef.current.element) || document).querySelector(".k-grid-container");
  if (gridContent) {
    return ReactDOM.createPortal(<Spinner />, gridContent);
  }
  return gridWrapper ? ReactDOM.createPortal(<SpinnerTable />, gridWrapper) : <Spinner />;
};
