import React from "react";
import { Loader } from "@progress/kendo-react-indicators";

export const DefaultSpinner = (props) => {
  return (
    <div className="spinner" style={{ zIndex: 1000 }}>
      <Loader type={"infinite-spinner"} />
    </div>
  );
};

export const Spinner = (props) => {
  return (
    <div style={{ minHeight: "300px" }}>
      <div className="spinner" style={{ zIndex: 1000 }}>
        <Loader type={"converging-spinner"} themeColor="dark" />
      </div>
    </div>
  );
};

export const SpinnerTable = (props) => {
  return (
    <div className={"position-absolute"} style={{ height: "100", top: "50%", zIndex: 1000 }}>
      <div className="spinner" style={{ zIndex: 1000 }}>
        <Loader type={"converging-spinner"} themeColor="dark" />
      </div>
    </div>
  );
};
