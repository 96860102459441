import React from "react";
import { Card, CardBody } from "@progress/kendo-react-layout";

import { useGridData } from "../../../../context";

export const NoTableData = ({ text = "No records available." }) => {
  const { errorGridDataFetch } = useGridData();
  return (
    <Card>
      <CardBody style={{ minHeight: "40vh" }} className="align-items-center justify-content-center d-flex ">
        {!errorGridDataFetch ? (
          <span className="text-muted" role="contentinfo">
            {text}
          </span>
        ) : (
          <span className="text-danger" role="contentinfo">
            Too many results, please change your filters
          </span>
        )}
      </CardBody>
    </Card>
  );
};
