import React, { useState, useRef } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

import imagePlaceholder from "../../../../../assets/images/placeholder.jpg";

export const ImageCell = ({ dataItem, field, ...props }) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const anchor = useRef(null);
  const [show, setShow] = useState(false);

  const onClick = () => setShow((prevState) => !prevState);

  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      <img
        style={{ maxWidth: "50px", maxHeight: "50px" }}
        className="rounded img-fluid"
        src={dataItem[field]}
        alt={dataItem.description}
        onError={({ currentTarget }) => {
          currentTarget.src = imagePlaceholder;
        }}
        onClick={onClick}
        ref={anchor}
      />
      <Popup anchor={anchor.current} show={show} popupClass={"popup-content"}>
        <img
          style={{ maxWidth: "500px", maxHeight: "500px" }}
          className="rounded img-fluid"
          src={dataItem[field]}
          alt={dataItem.description}
          onError={({ currentTarget }) => {
            currentTarget.src = imagePlaceholder;
          }}
          onClick={onClick}
        />
      </Popup>
    </td>
  );
};
