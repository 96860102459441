import { useState, useCallback } from "react";

export const useGroupedColumns = (initialColumns) => {
  const [columns, setColumns] = useState(initialColumns);

  const onColumnReorderHandler = useCallback((e) => {
    setColumns((prevData) => {
      let counter = 0;
      return prevData.map((columnGroup) => {
        if (!columnGroup.children) return columnGroup;
        const columnGroupMatch = e.columns.find((updatedColumnGroup) => updatedColumnGroup.id === columnGroup.id);
        if (!columnGroupMatch) return columnGroup;
        const children = columnGroup.children
          .filter((val) => val.field !== "selected")
          .map((column, index) => {
            const columnMatch = columnGroupMatch.children.find((updatedColumn) => column.field === updatedColumn.field);
            if (columnMatch) {
              return {
                ...column,
                field: columnMatch.field,
                title: columnMatch.title,
                width: columnMatch.width,
                orderIndex: columnMatch.orderIndex + counter,
                show: columnMatch.show,
                className: columnMatch.className,
                format: columnMatch.format,
              };
            }
            return { ...column, orderIndex: index + counter };
          })
          .sort((a, b) => a.orderIndex > b.orderIndex);

        return { ...columnGroup, children };
      });
    });
  }, []);

  const onColumnResizeHandler = useCallback((e) => {
    if (e.end) {
      setColumns((columnGroups) => {
        //Find the updated column
        const updatedColumn = e.columns
          .filter((val) => Boolean(val.children))
          .map((val) => val.children)
          .flat()
          .find((column) => column.id === e.targetColumnId);

        //Update the column in columns state
        const updatedColumns = columnGroups.map((columnGroup) => {
          if (!columnGroup.children) return columnGroup;
          return {
            ...columnGroup,
            children: columnGroup.children.map((column) => {
              if (updatedColumn.field === column.field) return { ...column, width: e.newWidth };
              else return column;
            }),
          };
        });
        return updatedColumns;
      });
    }
  }, []);

  const onColumnsToggleHandler = useCallback((columns) => setColumns(columns), []);

  return { columns, setColumns, onColumnsToggleHandler, onColumnReorderHandler, onColumnResizeHandler };
};
