import React from "react";

import { NumericTextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";

export const NumericDropdownOptions = ({ onChange, label, name, value }) => {
  const onChangeHandler = (event, inputName) => {
    if (!event.value) {
      onChange(event);
    } else {
      const inputValue = { ...value, [inputName]: event.value };
      onChange({ ...event, target: { ...event.target, name }, value: inputValue });
    }
  };

  return (
    <div>
      <Label>
        {label}
        <div className="d-flex">
          <div className="d-flex align-items-center me-2">
            <Label editorId={"numericFrom"} className="me-2">
              From
            </Label>
            <NumericTextBox
              id={"numericFrom"}
              value={value && value.gte ? value.gte : null}
              onChange={(e) => onChangeHandler(e, "gte")}
              max={value && value.lte}
            />
          </div>
          <div className="d-flex align-items-center">
            <Label editorId={"numericTo"} className="me-2">
              To
            </Label>
            <NumericTextBox
              id={"numericTo"}
              value={value && value.lte ? value.lte : null}
              onChange={(e) => onChangeHandler(e, "lte")}
              min={value && value.get}
            />
          </div>
        </div>
      </Label>
    </div>
  );
};
