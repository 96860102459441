import * as React from "react";

import { AppBar, AppBarSection, AppBarSpacer } from "@progress/kendo-react-layout";
import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <AppBar themeColor="light">
      <AppBarSpacer
        style={{
          width: 4,
        }}
      />

      <AppBarSection>
        <Link className="navbar-brand" to="/">
          <span className="tagline">
            <span className="product">Scan Appraisal</span>
          </span>
        </Link>
      </AppBarSection>

      <AppBarSpacer
        style={{
          width: 32,
        }}
      />

      <AppBarSpacer />
    </AppBar>
  );
};
