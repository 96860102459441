import React from "react";

import { TableFilters } from "../../components/TableFilters/TableFilters";
import { BaseGrid } from "../../components/Table/BaseGrid/BaseGrid";

export const Home = () => {
  return (
    <div className="container-fluid p-5">
      <div className="row px-5">
        <div className="col-24">
          <div className="row mb-5">
            <TableFilters />
          </div>
          <BaseGrid displayNoRecordsComponent={true} />
        </div>
      </div>
    </div>
  );
};
