import React from "react";
import { createRoot } from "react-dom/client";
import "./theme.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GridDataContextProvider, NotificationsContextProvider, ReferenceDataContextProvider } from "./context";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <NotificationsContextProvider>
      <ReferenceDataContextProvider>
        <GridDataContextProvider>
          <App />
        </GridDataContextProvider>
      </ReferenceDataContextProvider>
    </NotificationsContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
