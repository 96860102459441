import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid, GridColumn, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

import { TableSpinner } from "../../Spinners/TableSpinner/TableSpinner";
import { DetailComponent } from "../shared/DetailComponent/DetailComponent";
import { CustomTableCell } from "../shared/CustomCells";
import { ColumnMenu } from "../shared/ColumnMenu/ColumnMenu";
import { NoTableData } from "../shared/NoTableData/NoTableData";
import { ExportToExcel } from "../shared/export/ExportToExcel/ExportToExcel";
import { ExportToPdf } from "../shared/export/ExportToPdf/ExportToPdf";
import { CustomPager } from "../shared/CustomPager/CustomPager";

import { useGridData } from "../../../context";
import { getUniqueValues, getReferenceDataFields } from "../../../utils";
import { BaseGridColumnsToggle } from "../shared/ColumnsToggle/BaseGridColumnsToggle";

export const BaseGrid = ({ displayNoRecordsComponent }) => {
  const _grid = useRef();

  const {
    gridData,
    loadingGrid,
    dataState,
    setDataState,
    columns,
    customCells,
    onColumnsToggleHandler,
    onColumnReorderHandler,
    onColumnResizeHandler,
  } = useGridData();

  const [gridReferenceData, setGridReferenceData] = useState({});
  const [generalData, setGeneralData] = useState([]);
  const [dataResult, setDataResult] = useState(process(generalData, dataState));

  useEffect(() => {
    const gridReferenceDataFields = getReferenceDataFields(columns);
    setGridReferenceData(getUniqueValues(gridData, gridReferenceDataFields));
    setGeneralData(gridData);
  }, [gridData]);

  useEffect(() => {
    setDataResult(process(generalData, dataState));
  }, [generalData, dataState]);

  const dataStateChange = (event) => setDataState(event.dataState);

  const expandChange = (event) => {
    const isExpanded = event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setDataResult({ ...dataResult });
  };

  //Custom Cells
  const CustomCell = useCallback(
    (rest) => <CustomTableCell {...rest} customCellOptions={customCells[rest.field] || {}} />,
    [customCells]
  );

  return (
    <div id="grid_wrapper" className="row">
      <div className="col-24">
        {loadingGrid && <TableSpinner />}
        {displayNoRecordsComponent && (!gridData || !gridData.length > 0) ? (
          <NoTableData />
        ) : (
          <Grid
            ref={_grid}
            className={"groupable_table"}
            resizable
            onColumnResize={onColumnResizeHandler}
            sortable
            groupable
            reorderable
            onColumnReorder={onColumnReorderHandler}
            data={dataResult}
            {...dataState}
            onDataStateChange={dataStateChange}
            detail={DetailComponent}
            expandField="expanded"
            onExpandChange={expandChange}
            pager={(rest) => <CustomPager {...rest} />}
            pageable={{ buttonCount: 6, pageSizes: [20, 50, 100, 200] }}
          >
            <GridToolbar>
              <div className="flex w-100">
                <div className="d-flex">
                  <BaseGridColumnsToggle initialColumns={columns} onSubmitColumns={onColumnsToggleHandler} />
                </div>
                <div className="ms-auto">
                  <ExportToExcel gridData={generalData} tableState={dataState} columns={columns} />
                  <ExportToPdf gridData={generalData} tableState={dataState} columns={columns} customCells={customCells} />
                </div>
              </div>
              <div id="top_pager" className={"w-100"}></div>
            </GridToolbar>

            {columns
              .filter((column) => Boolean(column.show))
              .map((column, index, arr) => {
                return (
                  column.show && (
                    <GridColumn
                      key={column.field}
                      {...column}
                      className={Object.values({ classes: column.classes, gridAlign: column.gridAlign }).join(" ").trim()}
                      sortable={!column.isNotSortable}
                      cell={customCells && customCells.hasOwnProperty(column.field) ? CustomCell : undefined}
                      columnMenu={(rest) => (
                        <ColumnMenu
                          {...rest}
                          data={column.customFilter && gridReferenceData[column.field]}
                          shouldRenderFilter={column.shouldRenderFilter}
                          customFilter={column.customFilter}
                        />
                      )}
                    />
                  )
                );
              })}
          </Grid>
        )}
      </div>
    </div>
  );
};
