import React from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { BaseDropDownList } from "../../../../../Inputs";
// import { Input } from "@progress/kendo-react-inputs";

export const ColumnMenuDropdownFilter = ({
  data,
  firstFilterProps,
  secondFilterProps,
  hideSecondFilter,
  logicData,
  logicValue,
  onLogicChange,
  defaultItem,
}) => {
  let hasValue = (value) => Boolean(value && value !== defaultItem);
  const onFirstFilterChange = (event) => {
    hasValue = hasValue(event.target.value);
    firstFilterProps.onChange({
      value: hasValue ? event.target.value : "",
      operator: hasValue ? "eq" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  const onSecondFilterChange = (event) => {
    hasValue = hasValue(event.target.value);
    secondFilterProps.onChange({
      value: hasValue ? event.target.value : "",
      operator: hasValue ? "eq" : "",
      syntheticEvent: event.syntheticEvent,
    });
  };

  // const onSecondOperatorChange = (e) => {
  //   secondFilterProps.onChange({ value: secondFilterProps.value, operator: e.value.operator, syntheticEvent: e.syntheticEvent });
  // };

  // const onSecondOperatorValueChange = (e) => {
  //   secondFilterProps.onChange({ value: e.value, operator: secondFilterProps.operator, syntheticEvent: e.syntheticEvent });
  // };

  return (
    <>
      <div className="k-filtercell">
        <BaseDropDownList
          filterable
          data={data}
          onChange={onFirstFilterChange}
          value={firstFilterProps.value || defaultItem}
          defaultItem={defaultItem}
        />
      </div>
      <div>
        <DropDownList
          className="w-50"
          data={logicData}
          value={logicValue}
          onChange={onLogicChange}
          textField="text"
          dataItemKey="operator"
        />
      </div>
      <div className="k-filtercell">
        <BaseDropDownList
          data={data}
          onChange={onSecondFilterChange}
          value={secondFilterProps.value || defaultItem}
          defaultItem={defaultItem}
        />
      </div>
      {/* <div className="k-filtercell">
        <DropDownList
          textField="text"
          dataItemKey="operator"
          data={secondFilterProps.operators}
          onChange={onSecondOperatorChange}
          value={secondFilterProps.operators.find((operator) => operator.operator === secondFilterProps.operator)}
          defaultItem={defaultItem}
        />
      </div>
      <div className="k-filtercell">
        <Input onChange={onSecondOperatorValueChange} value={secondFilterProps.value} />
      </div> */}
    </>
  );
};
