import React from "react";
import { ComboBox } from "@progress/kendo-react-dropdowns";
import { useFilter } from "../../../hooks";

export const BaseComboBox = ({ filterable = true, ...props }) => {
  const { data, filterChange } = useFilter(props.data);

  return (
    <ComboBox
      {...props}
      filterable={filterable}
      onFilterChange={filterable ? (props.onFilterChange ? props.onFilterChange : filterChange) : undefined}
      data={data}
    />
  );
};
