import { createContext, useState, useCallback, useMemo } from "react";

import { useGroupedColumns } from "../../hooks";
import { useReferenceData, useNotifications } from "../";
import { getData, createPreset, deletePreset } from "../../services";
import { NOTIFICATION_TYPE } from "../../utils/consts";
import { columns as initialColumns, customCells } from "./assets/columns";

const initialFiltersState = {
  "Customer First": "",
  "Customer Last": "",
  "Item Type": [],
  State: [],
  City: "",
  Zip: "",
  "PDF Text Extract": "",
  "Appraisal Date": {},
  "Appraisal Value": {},
};

export const GridDataContext = createContext({
  filters: initialFiltersState,
  gridData: [],
  loadingGrid: false,
  setLoadingGrid: () => {},
});
const GridDataContextProvider = ({ children }) => {
  const { addNotification } = useNotifications();
  const { refetchPresets } = useReferenceData();
  const { columns, setColumns, onColumnsToggleHandler, onColumnReorderHandler, onColumnResizeHandler } =
    useGroupedColumns(initialColumns);
  const [selectedPreset, setSelectedPreset] = useState(null);
  const [gridData, setGridData] = useState([]);
  const [filters, setFilters] = useState(initialFiltersState);
  const [dataState, setDataState] = useState({
    skip: 0,
    take: 50,
    sort: [],
    group: [],
  });
  const [errorGridDataFetch, seErrorGridDataFetch] = useState(false);
  const [loadingGrid, setLoadingGrid] = useState(false);

  const onFetchData = useCallback(async (inputs) => {
    try {
      setLoadingGrid(true);
      seErrorGridDataFetch(false);
      const data = await getData(inputs);
      setGridData(data);
    } catch (err) {
      console.log(err);
      seErrorGridDataFetch(true);
    } finally {
      setLoadingGrid(false);
    }
  }, []);

  const onRefetchData = useCallback(async () => {
    await onFetchData(filters);
  }, [filters, onFetchData]);

  const onFiltersSubmit = useCallback(
    async (inputs) => {
      setFilters(inputs);
      await onFetchData(inputs);
    },
    [onFetchData]
  );

  const onFiltersReset = useCallback(() => {
    setFilters({ ...initialFiltersState });

    setSelectedPreset(null);
    setGridData([]);
  }, []);

  const onChoosePreset = useCallback(
    (preset) => {
      const { columns, dataState, filters } = preset;
      columns && setColumns(columns);
      dataState && setDataState(dataState);
      setFilters(filters || {});

      setSelectedPreset(preset);
      onFetchData(filters);
    },
    [setColumns, setDataState, setFilters, setSelectedPreset, onFetchData]
  );

  const onPresetCreate = useCallback(
    async ({ presetName, user, isPublic, status, filters }) => {
      try {
        const responseData = await createPreset({
          name: presetName,
          user,
          isPublic,
          status,
          columns: columns,
          dataState: dataState,
          filters,
        });
        addNotification({
          type: NOTIFICATION_TYPE.success,
          text: `Preset "${responseData.data._id}" has been created successfully.`,
        });
        setTimeout(async () => {
          const presets = await refetchPresets();
          const preset = presets.find((preset) => preset._id === presetName);
          if (preset) {
            onChoosePreset(preset);
          }
        }, 1000);
      } catch (err) {
      } finally {
      }
    },
    [columns, dataState, addNotification, onChoosePreset, refetchPresets]
  );

  const onPresetUpdate = useCallback(
    async ({ filters }) => {
      try {
        const responseData = await createPreset({
          name: selectedPreset.name,
          columns: selectedPreset && selectedPreset.columns,
          dataState: selectedPreset && selectedPreset.dataState,
          filters,
        });
        addNotification({
          type: NOTIFICATION_TYPE.success,
          text: `Preset "${responseData.data._id}" has been updated successfully.`,
        });

        setTimeout(() => {
          refetchPresets();
        }, 1000);
      } catch (err) {
      } finally {
      }
    },
    [addNotification, refetchPresets, selectedPreset]
  );

  const onPresetClone = useCallback(
    async (presetName) => {
      try {
        const responseData = await createPreset({
          name: presetName,
          user: selectedPreset.user,
          isPublic: selectedPreset.isPublic,
          status: selectedPreset.status,
          columns: selectedPreset.columns,
          dataState: selectedPreset.dataState,
          filters: selectedPreset.filters,
        });
        addNotification({
          type: NOTIFICATION_TYPE.success,
          text: `Preset "${responseData.data._id}" has been cloned successfully.`,
        });
        setTimeout(async () => {
          const presets = await refetchPresets();
          const preset = presets.find((preset) => preset._id === presetName);
          if (preset) {
            onChoosePreset(preset);
          }
        }, 1000);
      } catch (err) {
      } finally {
      }
    },
    [addNotification, onChoosePreset, refetchPresets, selectedPreset]
  );

  const onPresetDelete = useCallback(
    async (presetId) => {
      try {
        await deletePreset(presetId);
        addNotification({ type: NOTIFICATION_TYPE.info, text: `Preset "${presetId}" has been deleted successfully.` });
        setTimeout(() => {
          refetchPresets();
          setSelectedPreset(null);
        }, 1000);
      } catch (err) {
      } finally {
      }
    },
    [addNotification, refetchPresets]
  );

  const providerValue = useMemo(
    () => ({
      columns,
      customCells,
      onColumnsToggleHandler,
      onColumnResizeHandler,
      onColumnReorderHandler,
      gridData,
      dataState,
      setDataState,
      filters,
      errorGridDataFetch,
      seErrorGridDataFetch,
      loadingGrid,
      onRefetchData,
      setLoadingGrid,
      onFiltersSubmit,
      onFiltersReset,
      onPresetCreate,
      onPresetUpdate,
      onPresetClone,
      onPresetDelete,
      onChoosePreset,
      selectedPreset,
    }),
    [
      columns,
      onColumnsToggleHandler,
      onColumnResizeHandler,
      onColumnReorderHandler,
      gridData,
      dataState,
      setDataState,
      filters,
      errorGridDataFetch,
      seErrorGridDataFetch,
      loadingGrid,
      setLoadingGrid,
      onRefetchData,
      onFiltersSubmit,
      onFiltersReset,
      onPresetCreate,
      onPresetUpdate,
      onPresetClone,
      onPresetDelete,
      onChoosePreset,
      selectedPreset,
    ]
  );
  return <GridDataContext.Provider value={providerValue}>{children}</GridDataContext.Provider>;
};

export default GridDataContextProvider;
