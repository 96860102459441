import React, { useState, useRef, useMemo } from "react";
import { process } from "@progress/kendo-data-query";
import { ExcelExport, ExcelExportColumn } from "@progress/kendo-react-excel-export";
import { Button } from "@progress/kendo-react-buttons";
import { EXCEL_EXPORT_BORDER, COLORS } from "../../../../../utils/consts";
import { recursiveGroupedData } from "../../../../../utils";

export const ExportToExcel = ({ gridData, tableState, columns, groupedGrid }) => {
  const [loadingExport, setLoadingExport] = useState(false);
  const exportExcelRef = useRef(null);

  const save = (component) => {
    const options = component.current.workbookOptions();
    const rows = options.sheets[0].rows;

    rows.forEach((row, index) => {
      if (index === 0 && row.type === "header") {
        const updatedCells = row.cells
          .map((cell) => {
            if (cell.value && cell.colSpan) {
              const span = cell.colSpan;
              return [{ ...cell, colSpan: 1 }, ...Array(span - 1).fill({ background: COLORS.white, color: COLORS.white })];
            }
            return [cell];
          })
          .flat();
        rows[index].cells = updatedCells;
      }
      if (row.type === "group-header") {
        const columnCellsCount = row.cells[row.level].colSpan;
        row.cells[row.level].colSpan = 1;
        for (let i = row.level + 1; i <= columnCellsCount; i++) {
          row.cells[i] = { background: COLORS.darkGrey, color: COLORS.white };
        }
      }
      if (row.type === "data") {
        if (index % 2 !== 0) {
          row.cells.forEach((cell) => {
            cell.background = "#ccc";
          });
        }
      }
    });

    component.current.save(options);
  };

  const onExportGrid = () => {
    setLoadingExport(true);
    save(exportExcelRef);
  };

  const onExportComplete = () => setLoadingExport(false);

  const { data } = process(gridData, { filter: tableState.filter, sort: tableState.sort, group: tableState.group });

  const exportData = useMemo(
    () =>
      recursiveGroupedData(data, (item) => ({
        ...item,
        HasStock: item.HasStock === "1" ? "Yes" : "No",
        AutoReorder: item.AutoReorder === "1" ? "Yes" : "No",
      })),
    [data]
  );
  return (
    <React.Fragment>
      <Button
        size="small"
        className="me-2"
        themeColor={"primary"}
        title="Export to Excel"
        onClick={onExportGrid}
        disabled={loadingExport}
        iconClass={loadingExport ? "spinner-border spinner-border-sm" : ""}
        icon={!loadingExport ? "excel" : undefined}
      >
        Export to Excel
      </Button>
      <ExcelExport
        fileName={"KendGridExport.xlsx"}
        data={exportData}
        ref={exportExcelRef}
        group={tableState.group}
        headerPaddingCellOptions={{ background: COLORS.white }}
        onExportComplete={onExportComplete}
        collapsible
      >
        {groupedGrid
          ? columns &&
            columns
              .filter((columnGroup) => Boolean(columnGroup.children))
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map((columnGroup, columnGroupIndex, columnGroupArr) => {
                return (
                  <ExcelExportColumn
                    key={columnGroup.field}
                    hidden={!columnGroup.show}
                    field={null}
                    title={columnGroup.title + (columnGroup.description || "")}
                    headerCellOptions={{
                      background: COLORS.white,
                      color: COLORS.black,
                      borderBottom: EXCEL_EXPORT_BORDER,
                      borderLeft: EXCEL_EXPORT_BORDER,
                      textAlign: "center",
                      bold: true,
                    }}
                    groupHeaderCellOptions={{ background: COLORS.darkGrey, color: COLORS.white }}
                  >
                    {columnGroup.children
                      .sort((a, b) => a.orderIndex - b.orderIndex)
                      .map((column, index, arr) => {
                        let leftArrValues = [];
                        let shouldHaveBorder = false;
                        if (columnGroupIndex !== columnGroupArr.length - 1) {
                          leftArrValues = arr.slice(index + 1);
                          if (!leftArrValues.some((column) => Boolean(column.show))) {
                            shouldHaveBorder = { borderRight: EXCEL_EXPORT_BORDER };
                          }
                        }
                        return (
                          <ExcelExportColumn
                            key={column.field}
                            hidden={!column.show}
                            field={column.field}
                            title={column.title}
                            width={column.exportExcelWidth}
                            cellOptions={{
                              ...shouldHaveBorder,
                              format: column.exportFormat || "general",
                              textAlign: column.textAlign,
                            }}
                            headerCellOptions={{
                              borderTop: EXCEL_EXPORT_BORDER,
                              borderBottom: EXCEL_EXPORT_BORDER,
                              borderLeft: EXCEL_EXPORT_BORDER,
                              borderRight: EXCEL_EXPORT_BORDER,
                              background: COLORS.white,
                              color: COLORS.black,
                              textAlign: "center",
                              bold: true,
                            }}
                            groupHeaderCellOptions={{ background: COLORS.darkGrey, color: COLORS.white }}
                          />
                        );
                      })}
                  </ExcelExportColumn>
                );
              })
          : columns &&
            columns
              .sort((a, b) => a.orderIndex - b.orderIndex)
              .map((column) => (
                <ExcelExportColumn
                  key={column.field}
                  hidden={!column.show}
                  field={column.field}
                  title={column.title}
                  width={column.exportExcelWidth || column.width}
                  cellOptions={{
                    format: column.exportFormat || "general",
                    textAlign: column.textAlign,
                  }}
                  headerCellOptions={{
                    background: COLORS.white,
                    color: COLORS.black,
                    textAlign: "center",
                    bold: true,
                  }}
                  groupHeaderCellOptions={{ background: COLORS.darkGrey, color: COLORS.white }}
                />
              ))}
      </ExcelExport>
    </React.Fragment>
  );
};
