export function createQuery(filters) {
  return Object.entries(filters).reduce((acc, [inputKey, inputValue]) => {
    if (inputKey === "Appraisal Date" || inputKey === "Appraisal Value") {
      if (inputValue && (inputValue.gte || inputValue.lte)) {
        acc.push({ range: { [inputKey]: inputValue } });
      }
    } else if (Array.isArray(inputValue)) {
      if (inputValue.length) {
        acc.push({ terms: { [inputKey + ".keyword"]: inputValue } });
      }
    } else if (typeof inputValue === "string") {
      if (inputValue) {
        acc.push({ wildcard: { [inputKey + ".keyword"]: { value: "*" + inputValue + "*", case_insensitive: true } } });
      }
    } else if (inputValue != null && inputValue !== "") {
      acc.push({ term: { [inputKey + ".keyword"]: inputValue } });
    }
    return acc;
  }, []);
}

export function createRange(value) {
  if (value && (value.gte || value.lte)) return { "Appraisal Date": value };
  else return null;
}
