import React from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const HrefCell = (props) => {
  const { hrefField, hrefText } = props.options;

  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      {(props.dataItem[hrefField] || props.dataItem[props.field]) && (
        <a
          className="text-primary"
          target="_blank"
          rel="noreferrer"
          href={props.dataItem[hrefField] || props.dataItem[props.field]}
        >
          {hrefText || props.dataItem[props.field]}
        </a>
      )}
    </td>
  );
};
