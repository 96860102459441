import React, { useState, useRef, useMemo } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { Input } from "@progress/kendo-react-inputs";
import { useGridData } from "../../../../../context";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const ClonePresetConfirmation = ({ show, setShow, presetsData, selectedPreset }) => {
  const formRef = useRef(null);
  const { onPresetClone } = useGridData();

  const [presetName, setPresetName] = useState("");

  const onChangeHandler = (e) => setPresetName(e.value);

  const onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    await onPresetClone(presetName, "testUser", true, "active");
    setShow(false);
  };

  const onContinueHandler = () => formRef && formRef.current.requestSubmit();

  const presetWithSameNameExist = useMemo(
    () => presetsData.some((preset) => preset._id === presetName.trim()),
    [presetsData, presetName]
  );
  return (
    <>
      {show && (
        <Dialog title={"Clone a preset?"} onClose={setShow}>
          <p className="text-center m-2">Are you sure you want to clone preset {selectedPreset.name}?</p>
          <form onSubmit={onSubmit} ref={formRef}>
            <div className="mb-2">
              <Input
                valid={!!(presetName && !presetWithSameNameExist)}
                validationMessage={presetWithSameNameExist ? `Preset with name "${presetName}" already exist.` : ""}
                label="Preset Name"
                style={{ width: "100%" }}
                value={presetName}
                onChange={onChangeHandler}
              />
            </div>
          </form>
          <DialogActionsBar>
            <Button onClick={setShow}>Cancel</Button>
            <Button type="button" themeColor={"primary"} onClick={onContinueHandler}>
              Clone
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
