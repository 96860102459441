import React, { useState } from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { BaseCheckbox } from "../../../../Inputs";

export const CheckboxCell = (props) => {
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const [value, setValue] = useState(props.dataItem[props.field]);

  const onBlurHandler = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value,
    });
  };
  const onChangeHandler = (e) => setValue(e.value);
  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      <BaseCheckbox value={value} onBlur={onBlurHandler} onChange={onChangeHandler} />
    </td>
  );
};
