import React, { useState, useRef } from "react";
import { Popup } from "@progress/kendo-react-popup";
import { Button } from "@progress/kendo-react-buttons";
import { ToggleBaseGridColumnsPopup } from "./ToggleBaseGridColumnsPopup/ToggleBaseGridColumnsPopup";

export const BaseGridColumnsToggle = ({ initialColumns, onSubmitColumns }) => {
  const _anchor = useRef(null);
  const [show, setShow] = useState(false);
  const [columns, setColumns] = useState(initialColumns);

  const onTogglePopup = () => setShow(!show);

  const handleChange = (columnIndex) => {
    setColumns((prevColumns) =>
      prevColumns.map((column, idx) => {
        if (columnIndex === idx) {
          return { ...column, show: !column.show };
        }
        return column;
      })
    );
  };

  const onSubmitColumnsHandler = () => {
    onSubmitColumns(columns);
    onTogglePopup();
  };
  const oneVisibleColumn = columns && columns.filter((c) => c.show).length === 1;
  return (
    <>
      <div ref={_anchor}>
        <Button size="small" icon="columns" onClick={onTogglePopup}>
          Toggle Columns
        </Button>
      </div>
      <Popup anchor={_anchor.current} show={show} popupClass={"popup-content"}>
        <ToggleBaseGridColumnsPopup
          columns={columns}
          handleChange={handleChange}
          oneVisibleColumn={oneVisibleColumn}
          onTogglePopup={onTogglePopup}
          onSubmitColumnsHandler={onSubmitColumnsHandler}
        />
      </Popup>
    </>
  );
};
