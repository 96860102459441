import axios from "../axiosInstance";

import referenceDataFields from "../assets/referenceDataFields.json";
import { createQuery } from "../../utils";

const searchDataUrl = process.env.REACT_APP_DATA_URL + "/_search";

export const getData = async (filters) => {
  const query = createQuery(filters);

  try {
    const dataSetRequest = await axios.post(searchDataUrl, {
      size: 10000,
      query: {
        bool: {
          must: query,
        },
      },
    });

    return dataSetRequest.data.hits.hits
      .map(({ _source, ...item }) => ({
        ...item,
        ..._source,
        "Appraisal Date": _source["Appraisal Date"] && new Date(_source["Appraisal Date"]),
        "Appraisal Value": _source["Appraisal Value"] && parseFloat(_source["Appraisal Value"]),
      }))
      .filter(Boolean);
  } catch (err) {
    console.log(err);
    throw new Error(err.response);
  }
};

export const getReferenceData = async () => {
  try {
    const refRequestData = await Promise.allSettled(
      referenceDataFields.map((referenceField) =>
        axios.post(searchDataUrl, {
          query: { match_all: {} },
          size: 0,
          aggs: {
            [referenceField]: {
              terms: { field: referenceField + ".keyword", size: 1000, order: { _term: "asc" } },
            },
          },
        })
      )
    );
    const refData = refRequestData.reduce((acc, refFieldData, index) => {
      const refName = referenceDataFields[index];
      if (refFieldData.status === "fulfilled") {
        acc[refName] = refFieldData.value.data.aggregations[refName].buckets.map((bucket) => bucket.key).filter(Boolean);
      } else {
        acc[refName] = [];
      }
      return acc;
    }, {});
    return refData;
  } catch (err) {
    throw new Error(err.response);
  }
};
