import React, { useCallback, useState } from "react";
import { process } from "@progress/kendo-data-query";
import { Button } from "@progress/kendo-react-buttons";
import { GRID_EXPORT_PDF_MAX_RECORDS } from "../../../../../utils/consts";

const PDFExportComponent = React.lazy(() => import("./ExportPDFContent"));

export const ExportToPdf = ({ gridData, tableState, columns, customCells, groupedGrid }) => {
  const { skip, take, ...exportToPdfState } = tableState;

  const [exportPDF, setExportPDF] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);

  const exportCallback = useCallback(() => {
    setLoadingPdf(() => false);
    setExportPDF(() => false);
  }, []);

  const exportPDFHandler = useCallback(() => {
    setLoadingPdf(() => true);
    setTimeout(() => setExportPDF(() => true), 100);
  }, []);

  const data = process(gridData, exportToPdfState);

  return (
    <React.Fragment>
      <Button
        size="small"
        className="me-2"
        themeColor={"primary"}
        title="Export to PDF"
        onClick={exportPDFHandler}
        disabled={loadingPdf || (gridData && gridData.length > GRID_EXPORT_PDF_MAX_RECORDS)}
        iconClass={loadingPdf ? "spinner-border spinner-border-sm" : ""}
        icon={!loadingPdf ? "pdf" : undefined}
      >
        Export to PDF
      </Button>

      {exportPDF && (
        <React.Suspense fallback={<span></span>}>
          <PDFExportComponent
            exportPDF={exportPDF}
            exportCallback={exportCallback}
            gridData={data}
            columns={columns}
            exportToPdfState={exportToPdfState}
            customCells={customCells}
            groupedGrid={groupedGrid}
          />
        </React.Suspense>
      )}
    </React.Fragment>
  );
};
