import React from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const AcronymCell = (props) => {
  const { data, textField = "name", dataItemKey = "id" } = props.options;
  const value =
    data && data.find((val) => val[dataItemKey] === (props.dataItem[props.field] || props.dataItem[`"${props.field}"`]));
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      {(value && value[textField]) || props.dataItem[props.field]}
    </td>
  );
};
