import React from "react";
import { GridColumnMenuFilter } from "@progress/kendo-react-grid";

import { ColumnMenuDropdownFilter } from "./ColumnMenuFilters";

export const ColumnMenu = ({ shouldRenderFilter = true, customFilter, data, ...props }) => {
  return (
    <React.Fragment>
      {shouldRenderFilter && (
        <GridColumnMenuFilter
          {...props}
          expanded={true}
          filterUI={customFilter && customFilter === "dropdown" && ((rest) => <ColumnMenuDropdownFilter data={data} {...rest} />)}
        />
      )}
    </React.Fragment>
  );
};
