import React from "react";
import { Checkbox } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { StackLayout, Card, CardBody } from "@progress/kendo-react-layout";

export const ToggleBaseGridColumnsPopup = ({
  columns,

  handleChange,
  oneVisibleColumn,
  onTogglePopup,
  onSubmitColumnsHandler,
}) => {
  return (
    <ul className="list-group list-group-flush py-3">
      <li className="list-group-item d-flex">
        <StackLayout orientation={"horizontal"} gap={15}>
          <Card className="k-flex">
            <CardBody>
              <div>
                {columns &&
                  columns.map((column, idx) => (
                    <div key={idx}>
                      <Checkbox
                        disabled={oneVisibleColumn && column.show}
                        value={column.show}
                        onChange={() => handleChange(idx)}
                        label={column.title}
                      />
                    </div>
                  ))}
              </div>
            </CardBody>
          </Card>
        </StackLayout>
      </li>
      <li className="list-group-item d-flex justify-content-between">
        <div className="d-flex k-justify-content-stretch mt-3 w-100">
          <Button onClick={onTogglePopup}>Cancel</Button>
          <Button className="ms-2" onClick={onSubmitColumnsHandler} themeColor={"primary"}>
            Submit
          </Button>
        </div>
      </li>
    </ul>
  );
};
