import { createContext, useState, useMemo, useCallback, useLayoutEffect } from "react";

import { getReferenceData, getPresetsData } from "../../services";

export const ReferenceDataContext = createContext({
  referenceData: {},
  loadingReference: false,
  presetsData: [],
  loadingPresets: false,
  addReferenceData: () => {},
  removeReferenceData: () => {},
  refetchPresets: () => {},
});
const ReferenceDataContextProvider = ({ children }) => {
  const [referenceData, setReferenceData] = useState({
    "Appraisal Date": [
      { title: "Year to Date", id: "YTD" },
      { title: "Last 12 months", id: "L12M" },
      { title: "Last 24 Months", id: "L24M" },
      { title: "Last 36 Months", id: "L36M" },
      { title: "Last 5 years", id: "L5Y" },
      { title: "Last year", id: "LY" },
      { title: "All", id: "EVER" },
    ],
    "Appraisal Value": [{ title: "Year to Date", id: "YTD" }],
  });
  const [loadingReference, setLoadingReference] = useState(false);
  const [presetsData, setPresetsData] = useState([]);
  const [loadingPresets, setLoadingPresets] = useState(false);

  const addReferenceData = useCallback(
    (referenceData) => setReferenceData((prevData) => ({ ...prevData, ...referenceData })),
    []
  );

  const removeReferenceData = useCallback((field) => {
    if (field) {
      setReferenceData(({ [field]: removedItem, ...prevData }) => prevData);
    } else {
      setReferenceData({});
    }
  }, []);

  const refetchPresets = useCallback(async () => {
    let presets;
    try {
      setLoadingPresets(true);
      presets = await getPresetsData();
      setPresetsData(presets);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingPresets(false);
    }
    return presets;
  }, []);

  useLayoutEffect(() => {
    async function onGetReferenceData() {
      try {
        setLoadingPresets(true);
        setLoadingReference(true);

        const refData = await getReferenceData();
        const presets = await getPresetsData();

        addReferenceData(refData);
        setPresetsData(presets);
      } catch (err) {
        console.log(err);
      } finally {
        setLoadingPresets(false);
        setLoadingReference(false);
      }
    }
    onGetReferenceData();
  }, [addReferenceData]);

  const providerValue = useMemo(
    () => ({
      referenceData,
      loadingReference,
      presetsData,
      loadingPresets,
      addReferenceData,
      removeReferenceData,
      refetchPresets,
    }),
    [referenceData, loadingReference, presetsData, loadingPresets, addReferenceData, removeReferenceData, refetchPresets]
  );

  return <ReferenceDataContext.Provider value={providerValue}>{children}</ReferenceDataContext.Provider>;
};

export default ReferenceDataContextProvider;
