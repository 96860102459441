import { PERIOD_VALUES_TO_TITLE } from "./consts";

export function getUniqueValues(data, fields) {
  return fields.reduce((acc, field) => {
    if (data && data.length && field in data[0]) {
      acc[field] = [
        ...new Set(
          data
            .map((val) => val[field])
            .filter(Boolean)
            .sort((a, b) => a.localeCompare(b))
        ),
      ].filter(Boolean);
    } else {
      acc[field] = [];
    }
    return acc;
  }, {});
}

export function getReferenceDataFields(columns) {
  return columns
    .map((column) => {
      if (column.children) {
        return column.children;
      } else {
        return [column];
      }
    })
    .flat()
    .filter((column) => column.customFilter && column.customFilter === "dropdown")
    .map((column) => column.field);
}

export function createSoldDataTitlePeriod(value) {
  if (!value) return "";
  const valueEntries = Object.entries(value);
  if (!valueEntries.length) {
    return "All Time";
  } else {
    return Object.entries(value)
      .map(([key, value]) => `${PERIOD_VALUES_TO_TITLE[key]}${value}`)
      .join(" - ");
  }
}

export function recursiveGroupedData(data, updateFn) {
  return data.map((item) => {
    if (item.items) {
      return { ...item, items: recursiveGroupedData(item.items, updateFn) };
    } else {
      return updateFn(item);
    }
  });
}
