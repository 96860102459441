import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Header } from "./components/Navigation/Header/Header";
import { NotificationAlerts } from "./components/Notifications/NotificationAlerts/NotificationAlerts";

import { Home } from "./pages/Home/Home";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="*" exact={false} element={<Home />} />
      </Routes>
      <NotificationAlerts />
    </BrowserRouter>
  );
}

export default App;
