import React, { useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import { useGridData, useReferenceData } from "../../../context";
import { BaseComboBox } from "../../Inputs";
import { UpdatePresetConfirmation } from "./shared/UpdatePresetConfirmation/UpdatePresetConfirmation";
import { CreatePresetConfirmation } from "./shared/CreatePresetConfirmation/CreatePresetConfirmation";
import { ClonePresetConfirmation } from "./shared/ClonePresetConfirmation/ClonePresetConfirmation";
import { DeletePresetConfirmation } from "./shared/DeletePresetConfirmation/DeletePresetConfirmation";

export const PresetsFilters = ({ filters }) => {
  const { selectedPreset, onChoosePreset } = useGridData();
  const { presetsData, loadingPresets } = useReferenceData();

  const [updatePresetVisible, setUpdatePresetVisible] = useState(false);
  const [createPresetVisible, setCreatePresetVisible] = useState(false);
  const [clonePresetVisible, setClonePresetVisible] = useState(false);
  const [deletePresetVisible, setDeletePresetVisible] = useState(false);

  const onUpdatePreset = () => setUpdatePresetVisible((prevState) => !prevState);
  const onClonePreset = () => setClonePresetVisible((prevState) => !prevState);
  const onCreatePreset = () => setCreatePresetVisible((prevSatate) => !prevSatate);
  const onDeletePreset = () => setDeletePresetVisible((prevSatate) => !prevSatate);

  return (
    <div className="d-flex">
      <ul className="align-self-end list-inline list-actions m-0 d-flex ms-auto">
        <BaseComboBox
          style={{ width: "300px" }}
          label="Available Presets"
          textField={"_id"}
          dataItemKey={"_id"}
          data={presetsData}
          onChange={(e) => onChoosePreset(e.value)}
          value={selectedPreset}
          loading={loadingPresets}
        />
      </ul>
      <ul className="align-self-end list-inline list-actions m-0 d-flex ms-2">
        {/* <DropDownButton text="Presets options" items={items} /> */}
        <Button disabled={!selectedPreset} type="button" icon="edit" className="me-2" onClick={onUpdatePreset}>
          Update
        </Button>
        <div className="me-2">
          <Button disabled={!selectedPreset} type="button" icon="copy" onClick={onClonePreset}>
            Clone
          </Button>
        </div>
        <div className="me-2">
          <Button disabled={!selectedPreset} type="button" themeColor={"error"} icon="trash" onClick={onDeletePreset}>
            Delete
          </Button>
        </div>
        <div>
          <Button themeColor={"primary"} type="button" icon="save" onClick={onCreatePreset}>
            Create
          </Button>
        </div>

        <UpdatePresetConfirmation show={updatePresetVisible} setShow={onUpdatePreset} filters={filters} />
        <ClonePresetConfirmation
          show={clonePresetVisible}
          setShow={onClonePreset}
          selectedPreset={selectedPreset}
          presetsData={presetsData}
        />
        <CreatePresetConfirmation
          show={createPresetVisible}
          setShow={onCreatePreset}
          presetsData={presetsData}
          filters={filters}
        />
        <DeletePresetConfirmation show={deletePresetVisible} setShow={onDeletePreset} selectedPreset={selectedPreset} />
      </ul>
    </div>
  );
};
