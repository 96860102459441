const FIELD_FORMAT = Object.freeze({
  currency: {
    format: "{0:c0}",
    exportFormat: "[$$-409]#,##0;-[$$-409]#,##0;_(* -??_);_(@_)",
  },
  percentage: {
    format: "{0:p}",
    exportFormat: "0%",
  },
});

const ALIGN_FORMAT = Object.freeze({
  center: {
    gridAlign: "text-center",
    textAlign: "center",
  },
  right: {
    gridAlign: "text-end",
    textAlign: "right",
  },
  left: {
    gridAlign: "text-start",
    textAlign: "left",
  },
});

export const columns = [
  { field: "_id", title: "Id", show: false, width: 150 },
  { field: "UNIQUEID", title: "UNIQUEID", show: false, width: 200, locked: true },
  { field: "Image", title: "Image", show: false, width: 200 },
  {
    field: "Appraisal Date",
    title: "Date",
    show: true,
    width: 100,
    format: "{0:MM/dd/yyyy}",
    exportFormat: "MM/DD/YY",
  },
  {
    field: "PDF URL",
    title: "PDF Url",
    show: false,
    width: 300,
  },
  { field: "Customer First", title: "First", show: true, width: 100 },
  { field: "Customer Middle", title: "Middle", show: true, width: 100 },
  { field: "Customer Last", title: "Last", show: true, width: 100 },
  {
    field: "Extracted Item Type",
    title: "Extracted Item Type",
    show: false,
    width: 200,
  },
  { field: "Item Type", title: "Item Type", show: true, width: 250, customFilter: "dropdown" },
  { field: "Appraisal Value", title: "Value", show: true, width: 100, ...FIELD_FORMAT.currency, ...ALIGN_FORMAT.right },
  {
    field: "Street",
    title: "Street",
    show: false,
    width: 150,
    customFilter: "dropdown",
  },
  { field: "City", title: "City", show: true, width: 100 },
  { field: "Extracted State", title: "Extracted State", show: true, width: 200 },
  { field: "State", title: "State", show: true, width: 100 },
  { field: "Zip", title: "Zip", show: true, width: 100 },
  { field: "Employee First", title: "Employee First", show: false, width: 200 },
  { field: "Employee Middle", title: "Employee Middle", show: false, width: 200 },
  { field: "Employee Last", title: "Employee Last", show: false, width: 200 },
  { field: "PDF Text Extract", title: "PDF Text Extract", show: false, width: 200 },
  { field: "PDF Text File", title: "PDF Text File", show: false, width: 200 },
];

export const customCells = {};
