import React, { useState, useEffect } from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { NumericTextBox } from "@progress/kendo-react-inputs";

export const NumericTextboxCell = (props) => {
  const itemValue = props.dataItem[props.field];
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const [value, setValue] = useState(itemValue);

  useEffect(() => {
    setValue(itemValue);
  }, [itemValue]);

  const onBlurHandler = (e) => {
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value: { [props.field]: value, [`_webhook${props.field}`]: value },
    });
  };
  const onChangeHandler = (e) => setValue(e.value);
  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      <NumericTextBox value={value ? parseInt(value) : null} onBlur={onBlurHandler} onChange={onChangeHandler} />
    </td>
  );
};
