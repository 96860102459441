import axios from "../axiosInstance";

const presetsDataUrl = process.env.REACT_APP_PRESET_DATA_URL;

export const getPresetsData = async (ids) => {
  const query = {
    query: {
      match_all: {},
    },
  };

  try {
    const data = await axios.post(presetsDataUrl + "/_search?size=10000", query);

    return data.data.hits.hits.map(({ _source, ...preset }) => ({ ...preset, ..._source }));
  } catch (err) {
    console.log(err);
  }
};

export const createPreset = async (body) => {
  try {
    const data = await axios.post(presetsDataUrl + "/_doc/" + body.name, body);

    return data;
  } catch (err) {
    console.log(err);
  }
};

export const deletePreset = async (id) => {
  try {
    const data = await axios.delete(presetsDataUrl + "/_doc/" + id);

    return data;
  } catch (err) {
    console.log(err);
  }
};
