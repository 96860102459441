import React from "react";
import { GridCell } from "@progress/kendo-react-grid";
import { ImageCell } from "./ImageCell/ImageCell";
import { TruncateCell } from "./TruncateCell/TruncateCell";
import { AcronymCell } from "./AcronymCell/AcronymCell";
import { HrefCell } from "./HrefCell/HrefCell";
import { DropdownCell } from "./DropdownCell/DropdownCell";
import { NumericTextboxCell } from "./NumericTextboxCell/NumericTextboxCell";
import { CheckboxCell } from "./CheckboxCell/CheckboxCell";
import { DropdownValueCell } from "./DropdownValueCell/DropdownValueCell";

export const CustomTableCell = ({ customCellOptions, ...props }) => {
  const { type, ...options } = customCellOptions;
  switch (type) {
    case "ImageCell":
      return <ImageCell {...props} />;
    case "TruncateCell":
      return <TruncateCell {...props} />;
    case "HrefCell":
      return <HrefCell {...props} options={options} />;
    case "AcronymCell":
      return <AcronymCell {...props} options={options} />;
    case "DropdownCell":
      return <DropdownCell {...props} options={options} />;
    case "DropdownValueCell":
      return <DropdownValueCell {...props} options={options} />;
    case "NumericTextboxCell":
      return <NumericTextboxCell {...props} />;
    case "CheckboxCell":
      return <CheckboxCell {...props} />;
    default:
      return <GridCell {...props} />;
  }
};

export const CustomExportPDFTableCell = ({ customCellOptions, ...props }) => {
  const { type, ...options } = customCellOptions;
  switch (type) {
    case "ImageCell":
      return <HrefCell {...props} options={options} />;
    case "TruncateCell":
      return <TruncateCell {...props} />;
    case "HrefCell":
      return <HrefCell {...props} options={options} />;
    case "AcronymCell":
      return <AcronymCell {...props} options={options} />;
    default:
      return <GridCell {...props} />;
  }
};
