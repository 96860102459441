import React from "react";
import { formatDate } from "@telerik/kendo-intl";

import { DatePicker } from "@progress/kendo-react-dateinputs";
import { Label } from "@progress/kendo-react-labels";

export const DatePickerDropdownOptions = ({ onChange, label, name, value }) => {
  const onChangeHandler = (event, inputName) => {
    if (!event.value) {
      onChange(event);
    } else {
      const inputValue = { ...value, [inputName]: formatDate(event.value, "yyyy-MM-dd") };
      onChange({ ...event, target: { ...event.target, name }, value: inputValue });
    }
  };
  return (
    <div>
      <Label>
        {label}
        <div className="d-flex">
          <div className="d-flex align-items-center me-2">
            <Label editorId={"datePeriodFrom"} className="me-2">
              From
            </Label>
            <DatePicker
              id={"datePeriodFrom"}
              value={value && value.gte ? new Date(value.gte) : null}
              onChange={(e) => onChangeHandler(e, "gte")}
              max={value && value.lte && new Date(value.lte)}
            />
          </div>
          <div className="d-flex align-items-center">
            <Label editorId={"datePeriodTo"} className="me-2">
              To
            </Label>
            <DatePicker
              id={"datePeriodTo"}
              value={value && value.lte ? new Date(value.lte) : null}
              onChange={(e) => onChangeHandler(e, "lte")}
              min={value && value.gte && new Date(value.gte)}
            />
          </div>
        </div>
      </Label>
    </div>
  );
};
