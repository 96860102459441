import React, { useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";

import { useGridData, useReferenceData } from "../../context";

import { CustomInput } from "../Inputs/CustomInput";

import inputFields from "./assets/inputs.json";
import { PresetsFilters } from "./PresetsFilters/PresetsFilters";

export const TableFilters = () => {
  const { referenceData, loadingReference } = useReferenceData();
  const { filters, onFiltersSubmit, onFiltersReset } = useGridData();

  const [inputs, setInputs] = useState({});

  useEffect(() => {
    setInputs(filters);
  }, [filters]);

  const onSubmitHandler = (e) => {
    e.preventDefault();
    onFiltersSubmit(inputs);
  };

  const onResetHandler = (e) => {
    e.preventDefault();
    onFiltersReset();
  };

  const onChangeHandler = (e) => setInputs((prevState) => ({ ...prevState, [e.target.name]: e.value }));

  return (
    <>
      <form className="col-24" onSubmit={onSubmitHandler} onReset={onResetHandler}>
        <fieldset className="row mb-4">
          <div className="d-flex">
            <legend>Filters</legend>
            <PresetsFilters filters={inputs} />
          </div>
          {inputFields.map((fieldSet, index) => (
            <div key={index} className={fieldSet.wrapperClass}>
              {fieldSet.inputs.map((input, index) => (
                <CustomInput
                  inputType={input.inputType}
                  key={index}
                  onChange={onChangeHandler}
                  style={{ width: "100%" }}
                  label={input.label}
                  name={input.field}
                  data={referenceData[input.field] || []}
                  value={inputs[input.field]}
                  loading={loadingReference}
                />
              ))}
            </div>
          ))}
        </fieldset>
        <div className="d-flex">
          <ul className="list-inline list-actions m-0 d-flex ms-auto me-auto">
            <Button size={"large"} className="me-3" type="reset">
              Reset
            </Button>
            <Button size={"large"} themeColor={"primary"} type="submit">
              Search
            </Button>
          </ul>
        </div>
      </form>
    </>
  );
};
