import { createContext, useState, useCallback } from "react";
import { NOTIFICATION_TYPE } from "../../utils/consts";

export const NotificationsContext = createContext({
  notifications: [],
  addNotification: () => {},
  removeNotificaiton: () => {},
});
const NotificationsContextProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);

  const addNotification = useCallback((notification) => {
    const id = Math.random() * 1000;
    setNotifications((prevNotifications) => [...prevNotifications, { ...notification, id, show: true }]);
    if (notification.type.toLowerCase() !== NOTIFICATION_TYPE.error) {
      setTimeout(() => fadeNotification(id), 5000);
    }
  }, []);

  const fadeNotification = useCallback((id) => {
    setNotifications((prevNotifications) =>
      prevNotifications.map((notification) => (notification.id === id ? { ...notification, show: false } : notification))
    );
  }, []);

  const removeNotification = useCallback((id) => {
    setNotifications((prevNotifications) => prevNotifications.filter((notification) => notification.id !== id));
  }, []);

  return (
    <NotificationsContext.Provider value={{ notifications, addNotification, fadeNotification, removeNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export default NotificationsContextProvider;
