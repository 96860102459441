import React, { useState, useEffect, useMemo } from "react";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { BaseDropDownList } from "../../../../Inputs";

export const DropdownCell = ({ data, ...props }) => {
  const itemValue = props.dataItem[props.field];
  const { dataItemKey = "_id", textField = "Description", filterBy, resetValues, additionalValues } = props.options;
  const navigationAttributes = useTableKeyboardNavigation(props.id);
  const [value, setValue] = useState(null);

  useEffect(() => {
    if (data) {
      setValue(data.find((val) => val[textField] === itemValue));
    }
  }, [itemValue, textField, data]);

  const onChangeHandler = (e) => {
    setValue(e.value);
    const value = { [props.field]: e.value[textField], [`_webhook${props.field}`]: e.value[dataItemKey] };
    if (resetValues) {
      resetValues.forEach((val) => {
        if (e.value === null) return;
        value[val.fieldToSave] = val.value;
      });
    }
    if (additionalValues) {
      additionalValues.forEach((val) => {
        if (e.value === null) return;
        value[val.fieldToSave] = e.value[val.fieldValue];
      });
    }
    props.onChange({
      dataItem: props.dataItem,
      field: props.field,
      syntheticEvent: e.syntheticEvent,
      value,
    });
  };

  const dropdownData = useMemo(() => {
    if (filterBy && Array.isArray(filterBy.filters) && data) {
      const filteredData = data.filter((val) =>
        filterBy.filters.some((filterSet) =>
          filterSet.every((filter) => {
            if (!props.dataItem[filter.dataItemField] && filterBy.showAll) return true;
            return filter.operator === "eq"
              ? val[filter.compareField] &&
                  props.dataItem[filter.dataItemField] &&
                  val[filter.compareField] === props.dataItem[filter.dataItemField]
              : val[filter.compareField] &&
                  props.dataItem[filter.dataItemField] &&
                  val[filter.compareField].includes(+props.dataItem[filter.dataItemField]);
          })
        )
      );

      return filteredData;
    } else {
      return data;
    }
  }, [data, filterBy, props.dataItem]);

  return (
    <td
      style={{ ...props.style }}
      className={props.className}
      colSpan={props.colSpan}
      role={"gridcell"}
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...navigationAttributes}
    >
      <BaseDropDownList
        value={value}
        onChange={onChangeHandler}
        data={dropdownData}
        dataItemKey={dataItemKey}
        textField={textField}
      />
    </td>
  );
};
