import React from "react";
import { Button } from "@progress/kendo-react-buttons";

import { useGridData } from "../../../../../context";

import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

export const UpdatePresetConfirmation = ({ show, setShow, filters }) => {
  const { onPresetUpdate } = useGridData();

  const onContinueHandler = async () => {
    await onPresetUpdate({ filters });
    setShow(false);
  };

  return (
    <>
      {show && (
        <Dialog title={"Update a preset?"} onClose={setShow}>
          <p className="text-center m-2">Are you sure you want to continue?</p>
          <DialogActionsBar>
            <Button onClick={setShow}>Cancel</Button>
            <Button type="button" themeColor={"primary"} onClick={onContinueHandler}>
              Update
            </Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
