import React, { useEffect } from "react";
import { Checkbox } from "@progress/kendo-react-inputs";

export const BaseCheckbox = (props) => {
  const onChangeHandler = (e) => {
    props.onChange({
      ...e,
      target: { ...e.target, name: props.name },
      value: props.values ? (e.value ? props.values.true : props.values.false) : e.value,
    });
  };
  useEffect(() => {
    return () => {
      props.onChange({ target: { name: props.name }, value: null });
    };
  }, []);
  return (
    <Checkbox
      className="me-2"
      label={props.label}
      name={props.name}
      value={props.values && props.value ? props.value === props.values.true : props.value}
      checked={props.values && props.value ? props.value === props.values.true : props.value}
      onChange={onChangeHandler}
      disabled={props.disabled}
    />
  );
};
